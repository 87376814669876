<template>
  <b-overlay :show="isLoading" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card v-if="agent">
      <b-card-title>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <span>
            ตั้งค่าการคืนยอดเสีย
          </span>
          <b-form-group label="เปิด / ปิด" label-for="customDelimiter">
            <b-form-checkbox v-model="agent.cashback_status" :value="1" :unchecked-value="0"
              class="custom-control-success" name="check-button" switch @change="setCashbackStatus">
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </div>
      </b-card-title>

      <b-card-body>
        <b-row>
          <b-col cols="4">
            <b-form-group label="% การคืนยอด" label-for="customDelimiter">
              <b-form-input type="number" v-model="agent.cashback_percent" placeholder="% การคืนยอด" />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Turn (เท่า)" label-for="customDelimiter">
              <b-form-input type="number" v-model="agent.cashback_turn" placeholder="Turn (เท่า)" />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="รับได้สูงสุดต่อวัน" label-for="customDelimiter">
              <b-form-input type="number" v-model="agent.cashback_max_per_day" placeholder="รับได้สูงสุดต่อวัน" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button @click="submit" block variant="success">บันทึก</b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import { BIconController, BButton, BFormInput, BRow, BCol, BCardBody, BCard, BCardTitle, BFormGroup, BFormCheckbox, BOverlay } from 'bootstrap-vue';

export default {
  components: {
    BIconController, BButton, BFormInput, BRow, BCol, BCardBody, BCard, BCardTitle, BFormGroup, BFormCheckbox, BOverlay
  },
  data: () => ({
    agent: null,
    isLoading: false,
    form: {
      cashback_percent: null,
      cashback_turn: null,
      cashback_max_per_day: null
    }
  }),
  mounted() {
    this.getAg();
  },
  methods: {
    async getAg() {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get('/agent/show')

        this.agent = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async submit() {
      try {
        const { data } = await this.$http.post('/cashback/update', {
          cashback_percent: this.agent.cashback_percent,
          cashback_turn: this.agent.cashback_turn,
          cashback_max_per_day: this.agent.cashback_max_per_day
        });

        this.$swal({
          icon: 'success',
          title: `<h3 style="color: #141414">ทำรายการสำเร็จ</h3>`,
          text: data.message,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })

        this.getAg();
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: '<h3 style="color: #141414">ทำรายการไม่สำเร็จ!</h3>',
          text: error.response.data.message,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }
    },
    async setCashbackStatus(status) {
      try {
        const { data } = await this.$http.post('/cashback/setStatus', {
          cashback_status: status
        });

        this.$swal({
          icon: 'success',
          title: `<h3 style="color: #141414">ทำรายการสำเร็จ</h3>`,
          text: data.message,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })

        this.getAg();
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: '<h3 style="color: #141414">ทำรายการไม่สำเร็จ!</h3>',
          text: error.response.data.message,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }
    }
  }
}
</script>